export const BSC_RPC = "https://bsc-dataseed2.binance.org/"
export const MAX_UINT = '1000000000000000000000000000'

export const BUSD_CONTRACT_ADDRESS = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
export const USDT_CONTRACT_ADDRESS = '0x55d398326f99059fF775485246999027B3197955'
export const TICKET_CONTRACT_ADDRESS = "0x33F605CF10F2C865950839389ac6f0b6C3D7AC0C"

export const BANNER_BEAR_API_KEY = 'bb_pr_f270b208b35f89f8727b208f1be948'

export const THE_BROS_STUDIO_BOT = '5571695841:AAGYpfjxdC0Y9pAqfk-gZ5N85MHzuZsMalU'
export const THE_BROS_STUDIO_CHANNEL = '-1001638221891'