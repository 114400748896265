import { Button, InputNumber } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useWindowSize, validate_email, validate_phonenumber } from '../../../utils/utils'

const ContentArea = styled.div`
  margin: 50px auto;
  max-width: 570px;
  display: flex;
  flex-direction: column;
  padding: 6px;
  font-size: 16px;
  &.booking-area {
    max-width: 100vw;
    width: 100vw;
    margin: 0;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
`

const Title = styled.div`
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 50px;
`

const BookingRow = styled.div`
  width: 50%;
  margin: 10px auto;
  display: grid;
  grid-template-columns: 30% 70%;
  &.other {
    grid-template-columns: 30% 70%;
  }
  &.mobile {
    grid-template-columns: 40% 60%;
    width: 100%;
    padding: 0 25px;
  }
  &.tablet {
    grid-template-columns: 40% 60%;
    width: 70%;
    padding: 0 25px;
  }
`

const BookingButton = styled(Button)`
  margin-top: 20px;
  background: #0199D4;
  padding: 3px 30px 5px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  border: 2px solid #055c7d;
  &:hover {
    background: #fff;
    color: #000;
    border: 2px solid #000;
  }
`

const TextLabel = styled.div`
  text-align: left;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.mobile {
    font-size: 12px;
  }
`
const RequiredIcon = styled.span`
  color: red;
  margin-left: 5px;
`
const TextContent = styled.div`
  text-align: left;
`
const TextInput = styled.input`
  width: 100%;
  height: 30px;
  border: 1px solid black;
  border-radius: 5px;
`

const InputNumberStyled = styled(InputNumber)`
  height: 30px;
  border: 1px solid black;
  border-radius: 5px;
`

export default function FestivalBookingTicket({
  submit_guest_info
}) {
  const window_size = useWindowSize()
  const is_mobile = window_size.width <= 576
  const is_tablet = window_size.width < 992 && window_size.width > 576
  const [guest_name, set_guest_name] = useState(undefined)
  const [guest_phonenumber, set_guest_phonenumber] = useState(undefined)
  const [guest_email, set_guest_email] = useState(undefined)
  const [number_of_ticket, set_number_of_ticket] = useState(1)
  const [error, set_error] = useState(undefined)

  const showModal = () => {
    if (!guest_name || !guest_phonenumber || !guest_email || number_of_ticket === 0) {
      set_error('Vui lòng nhập đủ thông tin.')
      setTimeout(() => {
        set_error(undefined)
      }, 2000)
      return
    }
    if (!validate_phonenumber(guest_phonenumber)) {
      set_error('invalid phone number.')
      setTimeout(() => {
        set_error(undefined)
      }, 2000)
      return
    }
    if (!validate_email(guest_email)) {
      set_error('invalid email address.')
      setTimeout(() => {
        set_error(undefined)
      }, 2000)
      return
    }
    const guest = {
      name: guest_name,
      phone: guest_phonenumber,
      email: guest_email,
      number_of_ticket,
      total_price: number_of_ticket * 50
    }
    submit_guest_info(guest)
  };

  return (
    <ContentArea className='booking-area'>
      <Title>Thông tin nhận NFT</Title>
      <BookingRow className={is_mobile ? 'mobile' : '' || is_tablet ? 'tablet' : ''}>
        <TextLabel>Họ và tên <RequiredIcon>*</RequiredIcon></TextLabel>
        <TextContent>
          <TextInput type="text" value={guest_name} onChange={(event) => set_guest_name(event.target.value)} />
        </TextContent>
      </BookingRow>
      <BookingRow className={is_mobile ? 'mobile' : '' || is_tablet ? 'tablet' : ''}>
        <TextLabel>Số điện thoại <RequiredIcon>*</RequiredIcon></TextLabel>
        <TextContent>
          <TextInput type="text" value={guest_phonenumber} onChange={(event) => set_guest_phonenumber(event.target.value)} />
        </TextContent>
      </BookingRow>
      <BookingRow className={is_mobile ? 'mobile' : '' || is_tablet ? 'tablet' : ''}>
        <TextLabel>Email <RequiredIcon>*</RequiredIcon></TextLabel>
        <TextContent>
          <TextInput type="text" value={guest_email} onChange={(event) => set_guest_email(event.target.value)} />
        </TextContent>
      </BookingRow>
      <BookingRow className={is_mobile ? 'mobile' : '' || is_tablet ? 'tablet' : ''}>
        <TextLabel>Số lượng <RequiredIcon>*</RequiredIcon></TextLabel>
        <TextContent>
          <InputNumberStyled value={number_of_ticket} onChange={(value) => value >= 0 && set_number_of_ticket(value)} />
        </TextContent>
      </BookingRow>
      <BookingRow className={is_mobile ? 'mobile' : '' || is_tablet ? 'tablet' : ''}>
        <TextLabel>Phí</TextLabel>
        <TextContent style={{fontWeight: 'bold'}}>50 (BUSD/USDT)</TextContent>
      </BookingRow>
      <BookingRow className={is_mobile ? 'mobile' : '' || is_tablet ? 'tablet' : ''}>
        <TextLabel>Tổng cộng</TextLabel>
        <TextContent style={{fontWeight: 'bold'}}>{number_of_ticket * 50} (BUSD/USDT)</TextContent>
      </BookingRow>
      {error && (
        <BookingRow className={is_mobile ? 'mobile' : '' || is_tablet ? 'tablet' : ''} style={{ position: 'relative' }}>
          <TextLabel></TextLabel>
          <TextContent style={{color: 'red'}}>{error}</TextContent>
        </BookingRow>
      )}
      <BookingButton type="primary" onClick={showModal}>Tiếp</BookingButton>
    </ContentArea>
  )
}
