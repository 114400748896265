import { Button, Col, Row } from 'antd'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { useEffect, useState } from 'react'
import { NavLink, useParams } from "react-router-dom"
import styled from 'styled-components'
import { TICKET_CONTRACT_ADDRESS } from '../../config/constant'
import { shorten_address, useWindowSize } from '../../utils/utils'
import { connect_wallet } from '../../utils/web3'

const DetailPage = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: #040d21;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  color: #fff;
  &.mobile{
    padding: 25px;
  }
`

const Content = styled(Col)`
  margin-top: 100px;
  background: #282B1D;
  min-height: calc(100vh - 136px);
  height: 100%;
  padding: 50px;
  &.mobile{
    padding: 25px;
    margin: 100px 0 36px;
  }
`

const SaveImageButton = styled(Button)`
  border-radius: 5px;
  margin: 20px auto 10px;
`

const NFTDescription = styled.div`
  text-align: left;
  margin-top: 20px;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0 0px;
`

const ContentRow = styled(Row)`
  margin: 20px 0;
  height: 30px;
`

const ContentLabel = styled(Col)`
  display: flex;
  align-items: center;
`
const ContentText = styled(Col)`
  background: #D9D9D9;
  color: #000;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
`
const ContentTextNoBg = styled(Col)`
  color: #000;
  font-weight: bold;
  display: flex;
  align-items: center;
  border-radius: 5px;
`

export default function TicketDetail() {
  const window_size = useWindowSize()
  const is_mobile = window_size.width < 992
  const [this_ticket, set_this_ticket] = useState(undefined)
  const { id } = useParams()

  useEffect(() => {
    handle_connect_wallet()
    if (!window.ethereum) return
    window.ethereum.on('chainChanged', () => {
      handle_connect_wallet()
    })
    window.ethereum.on('accountsChanged', (accounts) => {
      const change_account = accounts[0]
      find_this_ticket(id, change_account)
    })
  }, [id])

  const handle_connect_wallet = async () => {
    connect_wallet()
    .then((current_account) => {
      find_this_ticket(id, current_account)
    })
    .catch((error) => {
      console.error('handle_connect_wallet', error)
      find_this_ticket(id, undefined)
    })
  }

  const find_this_ticket = async (id, account) => {
    try {
      const url = `https://ticket-api.thebros.studio/${account}/nft/${id}`
      const response = await axios.get(url)
      const ticket = response.data.ticket
      if (response.status === 200 && ticket) {
        return set_this_ticket(ticket)
      }
      return set_this_ticket(undefined)
    } catch (error) {
      console.error(error)
      return set_this_ticket(undefined)
    }
  }

  const render_space_effect = () => {
    return (
      <div className="overflow-hidden" style={{ height: '100vh', position: 'fixed', width: '100%', top: 1 }}>
        <img src="https://thebros.vn/hero-glow.svg" alt="" className="js-warp-hide position-absolute overflow-hidden home-hero-glow events-none"/>
        <div className="signup-space">
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
        </div>
      </div>
    )
  }

  if (!this_ticket) return (
    <DetailPage style={{ alignItems: 'center' }}>
      {render_space_effect()}
      <Col span={24} className='container'>
        <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold', marginBottom: 30 }}>Không tìm thấy NFT</div>
        <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold' }}>
          Quay trở lại trang <NavLink to='/account'>Tài khoản</NavLink> và thử lại
        </div>
      </Col>
    </DetailPage>
  )

  if (is_mobile) return (
    <DetailPage className='mobile'>
      {render_space_effect()}
      <Content sm={24} md={20} lg={20} xl={14} className='mobile'>
        <Row>
          <Col md={24} lg={10}>
            <div>
              <img
                style={{ width: '100%', marginBottom: 10 }}
                src={this_ticket.image}
                alt=''
              />
              <a
                href={this_ticket.image}
                target="_blank"
                rel="noreferrer"
                style={{textAlign: 'center'}}
              >
                (Lưu ảnh để checkin sự kiện)
              </a>
              <NFTDescription>
                <Title>MÔ TẢ</Title>
                <div>
                  <p>Được <b>TheBros</b> phát hành đại diện cho vé VIP tại <b>HAY GLAMPING MUSIC FESTIVAL</b>.</p>
                  <p>- Trọn bộ quà tặng: Túi tote, tấm trải picnic, quạt cầm tay, lightstick.</p>
                </div>
                <Title>THÔNG TIN NFT</Title>
                <Row>
                  <Col span={10}>Mạng</Col>
                  <Col span={14}>: Binance Smart Chain</Col>
                </Row>
                <Row>
                  <Col span={10}>Địa chỉ contract</Col>
                  <Col span={14}>
                    : <a href={`https://bscscan.com/address/${TICKET_CONTRACT_ADDRESS}`} target="_blank" rel='noreferrer'>
                      {shorten_address(TICKET_CONTRACT_ADDRESS)}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>NFT ID</Col>
                  <Col span={14}>: #{id}</Col>
                </Row>
              </NFTDescription>
            </div>
          </Col>
          <Col md={24} lg={14} style={{ marginTop: 20, width: '100%'}}>
            <Title style={{ margin: '0 0 20px' }}>THÔNG TIN NGƯỜI SỞ HỮU</Title>
            {this_ticket.customer ? (
              <NFTDescription>
                <ContentRow>
                  <ContentLabel span={10}>Tên</ContentLabel>
                  <ContentText span={14}>{this_ticket.customer.fullName}</ContentText>
                </ContentRow>
                <ContentRow>
                  <ContentLabel span={10}>Địa chỉ ví</ContentLabel>
                  <ContentText span={14}>{shorten_address(this_ticket.owner)}</ContentText>
                </ContentRow>
                <ContentRow>
                  <ContentLabel span={10}>Trạng thái</ContentLabel>
                  <ContentTextNoBg style={{ color: this_ticket.checkedIn ? 'red' : 'green' }} span={14}>
                    {this_ticket.checkedIn ? 'Đã sử dụng' : 'Chưa sử dụng'}
                  </ContentTextNoBg>
                </ContentRow>
              </NFTDescription>
            ) : (
              <div style={{textAlign: 'center'}}>
                <div>
                  Vui lòng kết nối địa chỉ ví chủ sở hữu ({shorten_address(this_ticket.owner)}) để xem thông tin
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Content>
    </DetailPage>
  )

  return (
    <DetailPage>
      {render_space_effect()}
      <Content sm={24} md={20} lg={20} xl={14} >
        <Row>
          <Col md={24} lg={14} style={{ paddingRight: 50 }}>
            <Title style={{ margin: '0 0 50px' }}>THÔNG TIN NGƯỜI SỞ HỮU</Title>
            {this_ticket.customer ? (
              <NFTDescription>
                <ContentRow>
                  <ContentLabel sm={24} md={6}>Tên</ContentLabel>
                  <ContentText sm={24} md={18}>{this_ticket.customer.fullName}</ContentText>
                </ContentRow>
                <ContentRow>
                  <ContentLabel sm={24} md={6}>Địa chỉ ví</ContentLabel>
                  <ContentText sm={24} md={18}>{shorten_address(this_ticket.owner)}</ContentText>
                </ContentRow>
                <ContentRow>
                  <ContentLabel sm={24} md={6}>Trạng thái:</ContentLabel>
                  <ContentTextNoBg style={{ color: this_ticket.checkedIn ? 'red' : 'green' }} sm={24} md={18}>
                    {this_ticket.checkedIn ? 'Đã sử dụng' : 'Chưa sử dụng'}
                  </ContentTextNoBg>
                </ContentRow>
              </NFTDescription>
            ) : (
              <div style={{textAlign: 'center'}}>
                <div>
                  Vui lòng kết nối địa chỉ ví chủ sở hữu ({shorten_address(this_ticket.owner)}) để xem thông tin
                </div>
              </div>
            )}
          </Col>
          <Col md={24} lg={10}>
            <div>
              <img
                style={{ width: '100%', marginBottom: 10 }}
                src={this_ticket.image}
                alt=''
              />
              <a
                href={this_ticket.image}
                target="_blank"
                rel="noreferrer"
                style={{textAlign: 'center'}}
              >
                (Lưu ảnh để checkin sự kiện)
              </a>
              <NFTDescription>
                <Title>MÔ TẢ</Title>
                <div>
                  <p>Được <b>TheBros</b> phát hành đại diện cho vé VIP tại <b>HAY GLAMPING MUSIC FESTIVAL</b>.</p>
                  <p>- Trọn bộ quà tặng: Túi tote, tấm trải picnic, quạt cầm tay, lightstick.</p>
                </div>
                <Title>THÔNG TIN NFT</Title>
                <Row>
                  <Col sm={24} md={8}>Mạng</Col>
                  <Col sm={24} md={16}>: Binance Smart Chain</Col>
                </Row>
                <Row>
                  <Col sm={24} md={8}>Địa chỉ contract:</Col>
                  <Col sm={24} md={16}>
                    : <a href={`https://bscscan.com/address/${TICKET_CONTRACT_ADDRESS}`} target="_blank" rel='noreferrer'>
                      {shorten_address(TICKET_CONTRACT_ADDRESS)}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} md={8}>NFT ID</Col>
                  <Col sm={24} md={16}>: #{id}</Col>
                </Row>
              </NFTDescription>
            </div>
          </Col>
        </Row>
      </Content>
    </DetailPage>
  )
}
