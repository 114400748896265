import { Button, Modal, notification, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { shorten_address, getDecimalAmount } from '../../../utils/utils'
import {
  approve_token,
  checksum_address, connect_wallet, get_allowance, ticket_contract
} from '../../../utils/web3'
import BigNumber from 'bignumber.js'
import {
  THE_BROS_STUDIO_BOT,
  THE_BROS_STUDIO_CHANNEL
} from '../../../config/constant'
import './style.scss'

const { Option } = Select;

const ConfirmRow = styled.div`
  width: 100%;
  margin: 10px auto;
  display: grid;
  grid-template-columns: 40% 60%;
`
const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
`

const TextLabel = styled.div`
  text-align: left;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.mobile {
    font-size: 12px;
  }
`
const TextContent = styled.div`
  text-align: left;
`

export default function FestivalBookingTicket({
  isModalVisible,
  setIsModalVisible,
  guest,
}) {
  const [current_account, set_current_account] = useState('')
  const [is_buying_ticket, set_is_buying_ticket] = useState(false)
  const [payment_token, set_payment_token] = useState('BUSD')
  const [is_approved, set_is_approved] = useState(false)
  const [is_approving, set_is_approving] = useState(false)
  const bnbFee = getDecimalAmount(new BigNumber(0.0008324), 18).toString()

  useEffect(() => {
    handle_connect_wallet()
    check_is_approve()
    if (!window.ethereum) return
    window.ethereum.on('chainChanged', () => {
      handle_connect_wallet()
    })
    window.ethereum.on('accountsChanged', () => {
      handle_connect_wallet()
    })
  }, [])

  const check_is_approve = async () => {
    if (!current_account) return
    const allowance = await get_allowance(current_account)
    if (allowance === 0) set_is_approved(false)
  }

  const handle_connect_wallet = async () => {
    connect_wallet()
    .then((current_account) => {
      set_current_account(current_account)
    })
    .catch((error) => {
      console.error('payment_modal',error)
      if (error.code === -32002) {
        return
      }
      openNotificationWithIcon('error', 'Lỗi', error.toString())
    })
  }

  const approve = async (token) => {
    set_is_approving(true)
    try {
      const approve = await approve_token(current_account, token)
      if (approve.status) {
        set_is_approved(true)
        set_is_approving(false)
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Lỗi', error.message)
      set_is_approving(false)
    }
  }

  const create_ticket_data = async (tokenIds) => {
    const interval = setInterval(() => {
      const data = {
        customer: {
          fullName: guest.name,
          phone: guest.phone,
          email: guest.email,
        },
        nftIds: tokenIds,
        eventId: 1,
        owner: current_account
      }
      axios({
        method: 'post',
        url: 'https://ticket-api.thebros.studio/tickets',
        headers: {},
        data: data
      })
        .then(async (response) => {
          console.log('response', response)
          if (response.status !== 200) return
          openNotificationWithIcon('success', 'Thành công', 'Nhận NFT thành công. Vui lòng kiểm tra trong Vé của tôi.')
          set_is_buying_ticket(false)
          setIsModalVisible(false)
          const bot_data = {
            name: guest.name,
            nftIds: tokenIds,
          }
          await sendMessageBotTelegram(bot_data)
          clearInterval(interval)
        })
        .catch((error) => {
          return error
        })
    }, 3000)
  }

  const sendMessageBotTelegram = async (data) => {
    let list_nft = ''
    for (let i = 0; i < data.nftIds.length; i++) {
      if (i === data.nftIds.length - 1) {
        list_nft += data.nftIds[i].toString()
      } else {
        list_nft += data.nftIds[i].toString() + ', '
      }
    }
    const message = `
      Khách hàng "${data.name}" vừa mua ${data.nftIds.length} NFTs (${list_nft}). Tổng số NFT đã bán: ${data.nftIds[data.nftIds.length - 1] - 22} NFTs
    `
    // Lấy giá trị nftId gần nhất trừ đi 3 nft test (chỉ trừ đi 2 do nftId bắt đầu từ 0) và trừ thêm 20 NFTs đã mint cho đối tác.
    axios({
      method: 'post',
      url: `https://api.telegram.org/bot${THE_BROS_STUDIO_BOT}/sendMessage?chat_id=${THE_BROS_STUDIO_CHANNEL}&text=${message}`,
    })
    .catch((error) => {
      console.error(error)
    })
  }

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
      top: 100,
      className: 'thebros-notification'
    });
  };

  const buy_ticket = async () => {
    set_is_buying_ticket(true)
    const to = current_account
    const ticketType = payment_token === 'BUSD' ? 1 : 2
    const amount = guest.number_of_ticket
    try {
      ticket_contract.methods.mint(to, ticketType, amount).send({ from: current_account, value: bnbFee })
        .then(async (response) => {
          console.log(response)
          const intervalListenEvent = setInterval(() => {
            ticket_contract.getPastEvents(
              'TicketMint',
              {
                fromBlock: response.blockNumber,
              },
              async (error, events) => {
                if (error) {
                  console.error(error)
                  openNotificationWithIcon('error', 'Lỗi', error.message)
                  set_is_buying_ticket(false)
                  return
                }
                if (Object.values(events).length === 0) return
                const this_event = Object.values(events).find((e) => checksum_address(e.returnValues.to) === checksum_address(current_account))
                if (!this_event) return
                console.log('TicketMint', this_event)
                const tokenIds = this_event.returnValues.tokenIds
                await create_ticket_data(tokenIds)
                clearInterval(intervalListenEvent)
              }
            )
          }, 3000)
        })
        .catch((error) => {
          openNotificationWithIcon('error', 'Lỗi', error.message)
          set_is_buying_ticket(false)
        })
    } catch (error) {
      openNotificationWithIcon('error', 'Lỗi', error.message)
      set_is_buying_ticket(false)
    }
  }

  const handleOk = () => {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  }
  
  return (
    <Modal title="XÁC NHẬN THANH TOÁN" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
      <ConfirmRow>
        <TextLabel>Tên</TextLabel>
        <TextContent>: {guest.name}</TextContent>
      </ConfirmRow>
      <ConfirmRow>
        <TextLabel>Số điện thoại</TextLabel>
        <TextContent>: {guest.phone}</TextContent>
      </ConfirmRow>
      <ConfirmRow>
        <TextLabel>Email</TextLabel>
        <TextContent>: {guest.email}</TextContent>
      </ConfirmRow>
      <ConfirmRow>
        <TextLabel>Số lượng vé</TextLabel>
        <TextContent>: {guest.number_of_ticket}</TextContent>
      </ConfirmRow>
      <ConfirmRow>
        <TextLabel>Tổng số tiền:</TextLabel>
        <TextContent>: {guest.total_price} (BUSD/USDT)</TextContent>
      </ConfirmRow>
      {current_account ? (
        <>
          <hr />
          <ConfirmRow>
            <TextLabel>Địa chỉ ví của bạn:</TextLabel>
            <TextContent>: {shorten_address(current_account)}</TextContent>
          </ConfirmRow>
          <div style={{ textAlign: 'center' }}>(Bạn có thể đổi địa chỉ bằng ví Metamask)</div>
          <ConfirmRow>
            <TextLabel>Chọn đồng giao dịch:</TextLabel>
            <Select
              defaultValue="BUSD"
              style={{
                width: 120,
              }}
              onChange={(value) => set_payment_token(value)}
            >
              <Option value="BUSD">BUSD</Option>
              <Option value="USDT">USDT</Option>
            </Select>
          </ConfirmRow>
        </>
      ) : null}
      <ConfirmButton>
        {current_account
          ? (
            <>
              {is_approved ? (
                <>
                  {is_buying_ticket ? (
                    <Button type='primary' disabled>Đang giao dịch ...</Button>
                  ) : (
                    <Button type='primary' onClick={buy_ticket}>Giao dịch</Button>
                  )}
                </>
              ) : (
                <>
                  {is_approving ? (
                    <Button type='primary' disabled>Đang xác nhận ...</Button>
                  ) : (
                    <>
                      {payment_token === 'BUSD' ? (
                        <Button type='primary' onClick={() => approve('BUSD')}>Xác nhận giao dịch bằng BUSD</Button>
                      ) : (
                        <Button type='primary' onClick={() => approve('USDT')}>Xác nhận giao dịch bằng USDT</Button>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )
          : <Button type='primary' onClick={handle_connect_wallet}>Kết nối ví</Button>
        }
      </ConfirmButton>
      {(is_buying_ticket || is_approving) && (
        <div style={{ textAlign: 'center' }}>Vui lòng chờ trong giây lát khi thực hiện giao dịch blockchain.</div>
      )}
    </Modal>
  )
}
