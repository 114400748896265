import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const HomePage = styled.div`
  padding: 100px 0 0;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: #040d21;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  &.mobile{
    padding: 25px;
  }
`

export default function Home() {

  const render_space_effect = () => {
    return (
      <div className="overflow-hidden" style={{ height: '100vh', position: 'fixed', width: '100%', top: 1 }}>
        <img src="https://thebros.vn/hero-glow.svg" alt="" className="js-warp-hide position-absolute overflow-hidden home-hero-glow events-none"/>
        <div className="signup-space">
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
        </div>
      </div>
    )
  }
  return (
    <HomePage style={{ alignItems: 'center', padding: 0 }}>
      {render_space_effect()}
      <div style={{zIndex: 2}}>
        <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold', marginBottom: 30 }}>Không tìm thấy trang</div>
        <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold' }}>
          Quay trở lại <NavLink to='/'>Trang chủ</NavLink>
        </div>
      </div>
    </HomePage>
  )
}
