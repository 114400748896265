import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'

const FestivalDestination = styled.div`
  width: 100%;
  position: relative;
  background: url('https://thebros.vn/hfest/bg_1.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const FestivalMap = styled(Row)`
  margin-left: 12px;
  margin-right: 12px;
  z-index: 99;
  max-width: 720px;
  background-color: rgb(255, 232, 24);
  border: 5px solid rgb(255, 232, 24);
  row-gap: 32px;
`
const ShowText = styled.div`
  font-size: 18px;
  font-weight: bold;
  &.mobile {
    font-size: 16px;
  }
  &.name {
    font-size: 23px;
    &.mobile {
      font-size: 18px;
    }
  }
`
export default function index() {
  return (
    <FestivalDestination>
      <FestivalMap>
        <Col sm={12} md={12} style={{ padding: '0 16px' }}>
          <ShowText>CÔNG VIÊN YÊN SỞ</ShowText>
          <ShowText>Gamuda City, HOANG MAI,</ShowText>
          <ShowText>HANOI, VIETNAM</ShowText>
        </Col>
        <Col sm={12} md={12} style={{ padding: '0 16px' }}>
          <ShowText>06.08.22</ShowText>
        </Col>
        <div style={{ zIndex: '99', height: 480, width: '100%' }}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7451.291112684999!2d105.85272827613525!3d20.966744512609157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ac34ca0c7c45%3A0xf9c593f4397d0470!2sYen%20So%20Park!5e0!3m2!1sen!2shk!4v1656147198641!5m2!1sen!2shk" height="100%" width="100%" loading="lazy" title="map"></iframe>
        </div>
      </FestivalMap>
    </FestivalDestination>
  )
}
