import { Col, Row, Select } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import NFT_Image from '../../assets/img/nft.png'
import { connect_wallet } from '../../utils/web3'
import './style.scss'

const { Option } = Select
const HomePage = styled.div`
  padding: 100px 0 0;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: #040d21;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  &.mobile{
    padding: 25px;
  }
`
const Product = styled(Col)`
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
`

const ShowImageContainer = styled.div`
  width: 100%;
`

const ShowAvatar = styled.img`
  width: 100%;
  border-radius: 16px;
`

const ContentRow = styled(Row)`
  background-color: #00000080;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  margin-top: 5px;
  border-radius: 5px;
`
const ContentLabel = styled(Col)`
  text-align: left;
`
const ContentText = styled(Col)`
  text-align: left;
`


export default function MyAssets() {
  const [all_tickets, set_all_tickets] = useState([])
  const [shown_tickets, set_shown_tickets] = useState([])
  const [fetching_data, set_fetching_data] = useState(false)

  useEffect(() => {
    fetch_account_data()
    if (!window.ethereum) return
    window.ethereum.on('chainChanged', () => {
      fetch_account_data()
    })
    window.ethereum.on('accountsChanged', (accounts) => {
      const change_account = accounts[0]
      get_my_assets(change_account)
    })
  },[])

  const fetch_account_data = async () => {
    set_fetching_data(true)
    try {
      const current_account = await connect_wallet()
      get_my_assets(current_account)
      set_fetching_data(false)
    } catch (error) {
      console.error(error)
      set_all_tickets([])
      set_shown_tickets([])
      set_fetching_data(false)
    }
  }

  const get_my_assets = async (account) => {
    try {
      const url = `https://ticket-api.thebros.studio/${account}/tickets`
      const response = await axios.get(url)
      if (response.status === 200) {
        set_all_tickets(response.data.tickets)
        set_shown_tickets(response.data.tickets)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const render_space_effect = () => {
    return (
      <div className="overflow-hidden" style={{ height: '100vh', position: 'fixed', width: '100%', top: 1 }}>
        <img src="https://thebros.vn/hero-glow.svg" alt="" className="js-warp-hide position-absolute overflow-hidden home-hero-glow events-none"/>
        <div className="signup-space">
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
        </div>
      </div>
    )
  }


  const handleChange = (value) => {
    const my_tickets_ref = all_tickets
    let shown
    if (value === 'used') {
      shown = my_tickets_ref.filter((t) => t.checkedIn === true)
    } else if (value === 'un-use') {
      shown = my_tickets_ref.filter((t) => t.checkedIn === false)
    } else {
      shown = all_tickets
    }
    set_shown_tickets(shown)
  };

  if (fetching_data) {
    return (
      <HomePage style={{ alignItems: 'center', padding: 0 }}>
        {render_space_effect()}
        <div style={{zIndex: 2}}>
          <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold', marginBottom: 30 }}>Đang tải dữ liệu ...</div>
        </div>
      </HomePage>
    )
  }
  if (all_tickets.length === 0) {
    return (
      <HomePage style={{ alignItems: 'center', padding: 0 }}>
        {render_space_effect()}
        <div style={{zIndex: 2}}>
          <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold', marginBottom: 30 }}>Bạn chưa có vé nào</div>
          <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold' }}>
            Quay trở lại <NavLink to='/'>Trang chủ</NavLink>
          </div>
        </div>
      </HomePage>
    )
  }
  return (
    <HomePage>
      {render_space_effect()}
      <Col sm={24} md={16} className='container'>
        <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold', margin: '30px auto 20px' }}>
          Bạn đang có {shown_tickets.length} NFTs
        </div>
        <Select
          defaultValue="Tất cả"
          style={{
            width: 150,
            marginBottom: 30,
            fontSize: 16,
            background: 'tranparent',
            textAlign: 'left',
          }}
          onChange={handleChange}
          className="filter-ticket"
        >
          <Option value="all">Tất cả</Option>
          <Option value="used">Đã sử dụng</Option>
          <Option value="un-use">Chưa sử dụng</Option>
        </Select>
        <Row>
          {
            shown_tickets.map((ticket, index) => (
              <Product xs={24} md={12} lg={8} xl={6} key={index}>
                <ShowImageContainer>
                  <NavLink to={`/hay-fest/${ticket.nftId}`}>
                    <ShowAvatar alt="" src={ticket.image === '' ? NFT_Image : ticket.image} />
                  </NavLink>
                </ShowImageContainer>
                <ContentRow>
                  <ContentLabel span={8}>NFT ID</ContentLabel>
                  <ContentText span={16}>#{ticket.nftId}</ContentText>
                </ContentRow>
                <ContentRow>
                  <ContentLabel span={8}>Trạng thái</ContentLabel>
                  <ContentText span={16} style={{ color: ticket.checkedIn ? 'red' : 'green'}}>
                    {ticket.checkedIn ? 'Đã sử dụng' : 'Chưa sử dụng'}
                  </ContentText>
                </ContentRow>
              </Product>
            ))
          }
        </Row>
      </Col>
    </HomePage>
  )
}
