import {useState, useEffect} from 'react'
import BigNumber from 'bignumber.js'

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
      });
      useEffect(() => {
        function handleResize() {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
      }, []);
      return windowSize;
}

export const shorten_address = (address) => {
  return `${address.slice(0,6)}...${address.slice(-6)}`
}

export const convert_text_to_index = (text) => {
  switch (text) {
    case 'A':
      return 0
    case 'B':
      return 1
    case 'C':
      return 2
    case 'D':
      return 3
    default:
      return undefined
  }
}

export const validate_email = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) return true
  return false
}

export const validate_phonenumber = (phone) => {
  return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(phone);
}

export const BIG_TEN = new BigNumber(10)

export const getDecimalAmount = (amount, decimals = 18) => {
  return new BigNumber(amount).times(BIG_TEN.pow(decimals))
}