import React from 'react'
import styled from 'styled-components'
import { useWindowSize } from '../../../utils/utils'
import NFT_Image from '../../../assets/img/nft.png'

const TicketCard = styled.div`
  // background: #fff;
  padding: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 70%;
  &.mobile {
    display: block;
    max-width: 100%;
  }
`

const TicketTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-align: left;
`
const TicketType = styled.div`
  font-size: 16px;
  text-align: left;
`
const TicketPrice = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  margin: 15px 0;
`
const TicketDescription = styled.div`
  text-align: left;
`
const NFTDescription = styled.div`
  text-align: left;
  color: #fff;
  padding: 0 25px;
  &.mobile {
    padding: 25px 0;
  }
`

const NFTImage = styled.img`
  width: 100%;
  max-width: 400px;
`

export default function PriceCard({ ticket }) {
  const window_size = useWindowSize()
  const is_mobile = window_size.width < 992
  // return (
  //   <TicketCard className={is_mobile ? 'mobile' : ''}>
  //     <TicketTitle>{ticket.ticket_name}</TicketTitle>
  //     <TicketType>{ticket.ticket_type}</TicketType>
  //     <TicketPrice>{ticket.ticket_price} (BUSD/USDT)</TicketPrice>
  //     <TicketDescription>
  //       {ticket.ticket_description.map((d) => (
  //         <div>{d}</div>
  //       ))}
  //     </TicketDescription>
  //   </TicketCard>
  // )
  return (
    <TicketCard className={is_mobile ? 'mobile' : ''}>
      <NFTImage src={NFT_Image} alt='' />
      <NFTDescription className={is_mobile ? 'mobile' : ''}>
        <div>Bạn đã sẵn sàng tham gia trải nghiệm glamping đúng chuẩn, music festival đúng điệu cùng <b>HAY FEST</b>?</div>
        <div style={{
          fontSize: 25,
          fontWeight: 'bold',
          padding: '20px 0'
        }}>NFT NÀY HAY PHẾT!</div>
        <p>1000 NFTs được <b>TheBros</b> phát hành đại diện cho 1000 vé VIP tại <b>HAY GLAMPING MUSIC FESTIVAL</b>.</p>
        <p>1000 cơ hội duy nhất để sở hữu tấm vé đại nhạc hội được phát hành dưới dạng NFT lần đầu tiên tại Việt Nam.</p>
        <p>- Mức giá ưu đãi: <b>50</b> (BUSD/USDT)</p>
        <p>- Trọn bộ quà tặng: Túi tote, tấm trải picnic, quạt cầm tay, lightstick.</p>
      </NFTDescription>
    </TicketCard>
  )
}
