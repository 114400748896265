import React from 'react';
import { Route } from 'react-router-dom';
const AppRoutes = ({ component: Component, path, isPrivate, ...rest }) => {
	return (
		<Route
			path={path}
			render={(props) =>
				// isPrivate && !Boolean(userDetails.token) ? (
				// 	<Redirect to={{ pathname: '/login' }} />
				// ) : 
                (
					<Component {...props} />
				)
			}
			{...rest}
		/>
	);
};

export default AppRoutes;
