import React from "react";
import routes from './config/router'
import AppRoute from './components/AppRoute'
import { Switch, BrowserRouter as Router } from "react-router-dom"
import Header from './components/Header';

import './App.scss';

function App() {

  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          {routes.map((route) => (
            <AppRoute
              key={route.path}
              path={route.path}
              component={route.component}
              isPrivate={route.isPrivate}
              exact
              is_mobile
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
