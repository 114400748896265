import React from 'react'
import { Col, Row } from 'antd'
import { NavLink } from 'react-router-dom'
import { SHOWS } from '../../config/mockup'
import styled from 'styled-components'
import './animation.scss';

const HomePage = styled.div`
  padding: 100px 0 0;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: #040d21;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  &.mobile{
    padding: 25px;
  }
`
const Product = styled(Col)`
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
`

const ShowImageContainer = styled.div`
  width: 100%;
  object-fit: contain;
  padding: 24px;
`

const ShowAvatar = styled.img`
  width: 100%;
  border-radius: 16px;
`

const ShowName = styled.div`
  background-color: #00000010;
  width: 100%;
  max-width: 240px;
  height: 160px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  cursor: pointer;
  padding: 12px;
  color: #fff;
  margin: 0 auto;
  &:hover {
    background-color: #00000030;
  }

  img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    padding: 12px;
  }
`

export default function Home() {

  const render_space_effect = () => {
    return (
      <div className="overflow-hidden" style={{ height: '100vh', position: 'fixed', width: '100%', top: 1 }}>
        <img src="https://thebros.vn/hero-glow.svg" alt="" className="js-warp-hide position-absolute overflow-hidden home-hero-glow events-none"/>
        <div className="signup-space">
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
        </div>
      </div>
    )
  }
  return (
    <HomePage>
      {render_space_effect()}
      <Col sm={24} md={16}>
        <Row style={{ justifyContent: 'center' }}>
          {
            SHOWS.map((show, index) => (
              <Product sm={12} md={10} key={index}>
                <ShowImageContainer>
                  <NavLink to={`/show/${show.id}`}>
                    <ShowAvatar alt="" src={show.avatar} />
                  </NavLink>
                </ShowImageContainer>
                <NavLink to={`/show/${show.id}`}>
                  <ShowName>
                      <img alt="logo" src={show.logo} />
                      <span>Hà Nội</span><span>Thủ đô Hà Nội</span>
                  </ShowName>
                </NavLink>
              </Product>
            ))
          }
        </Row>
      </Col>
    </HomePage>
  )
}
