import React from 'react'
import styled from 'styled-components'

const ContentArea = styled.div`
  flex-direction: column;
  padding: 6px;
  font-size: 16px;
  max-width: 100vw;
  width: 100vw;
  margin: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ShowImage = styled.img`
  width: 100%;
`

export default function index({product}) {
  return (
    <ContentArea className='booking-area'>
      <div className='booking-map'>
        <ShowImage src={product.map} style={{ maxWidth: '800px' }} />
      </div>
    </ContentArea>
  )
}
