import Home from "../pages/Home"
import MyAssets from "../pages/MyAssets"
import ProductDetail from "../pages/ProductDetail"
import TicketDetail from "../pages/TicketDetail"
import NotFound from "../pages/NotFound"

const router = [
	{
		path: '/',
		component: Home,
		isPrivate: true,
	},
	{
		path: '/account',
		component: MyAssets,
		isPrivate: true,
	},
	{
		path: '/show/:id',
		component: ProductDetail,
		isPrivate: true,
	},
	{
		path: '/hay-fest/:id',
		component: TicketDetail,
		isPrivate: true,
	},
	{
		component: NotFound,
	},
]

export default router