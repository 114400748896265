import { Button, notification } from 'antd'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useWindowSize, shorten_address } from '../../utils/utils'
import {connect_wallet, is_wrong_network, change_network} from '../../utils/web3'
import logo from '../../assets/img/logo.png'

const HeaderContainer = styled.div`
  height: 64px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  position: fixed;
  width: 100vw;
  z-index: 2;
  &.mobile {
    padding: 0 15px;
  }
`
const Logo = styled.img`
  width: 120px;
`

const AccountArea = styled.div`
  display: flex;
  align-items: center;
`

const CurrentAccount = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
  &.mobile {
    margin-right: 10px;
  }
`

const ConnectWalletButton = styled(Button)`
  background: #fff;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  &.mobile {
    font-size: 14px;
    padding: 4px 10px;
  }
`
const WrongNetworkButton = styled(Button)`
  background: red;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  &.mobile {
    font-size: 14px;
    padding: 4px 10px;
  }
`

export default function Header() {
  const window_size = useWindowSize()
  const is_mobile = window_size.width < 992
  const [current_account, set_current_account] = useState(undefined)
  const [wrong_network, set_wrong_network] = useState(false)

  useEffect(() => {
    if (!window.ethereum) return
    handle_connect_wallet()
    window.ethereum.on('chainChanged', () => {
      handle_connect_wallet()
    })
    window.ethereum.on('accountsChanged', () => {
      handle_connect_wallet()
    })
  }, [])

  const handle_connect_wallet = async () => {
    is_wrong_network()
    .then((response) => {
      set_wrong_network(response)
    })
    .catch((error) => {
      console.error(error)
    })
    connect_wallet()
    .then((current_account) => {
      set_current_account(current_account)
    })
    .catch((error) => {
      console.error('connect_wallet',error)
      if (error.code === -32002) {
        return
      }
      openNotificationWithIcon('error', 'Lỗi', error.toString())
    })
  }

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
      placement: 'top',
      top: 100,
      className: 'thebros-notification'
    });
  };

  return (
    <HeaderContainer className={is_mobile ? 'mobile' : ''}>
      <NavLink to={`/`}>
        <Logo src={logo} />
      </NavLink>
      <AccountArea>
        {current_account ? (
          <>
            <CurrentAccount className={is_mobile ? 'mobile' : ''}>{shorten_address(current_account)}</CurrentAccount>
            {wrong_network ? (
              <WrongNetworkButton className={is_mobile ? 'mobile' : ''} onClick={change_network}>WRONG NETWORK</WrongNetworkButton>
            ) : (
              <NavLink to={`/account`}>
                <ConnectWalletButton className={is_mobile ? 'mobile' : ''} type='primary'>VÉ CỦA TÔI</ConnectWalletButton>
              </NavLink>
            )}  
          </>
        ) : (
          <ConnectWalletButton className={is_mobile ? 'mobile' : ''} type='primary' onClick={handle_connect_wallet}>KẾT NỐI VÍ</ConnectWalletButton>
        )}
      </AccountArea>
    </HeaderContainer>
  )
}
