import React from 'react'
import styled from 'styled-components'
import TicketPriceCard from '../TicketPriceCard'
import { TICKET_DETAILS } from '../../../config/mockup'

const ContentArea = styled.div`
  flex-direction: column;
  padding: 6px;
  font-size: 16px;
  width: 100vw;
  margin: 0;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('https://thebros.vn/hfest/bg_3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
`
const Title = styled.div`
  font-size: 35px;
  font-weight: bold;
  margin: 30px auto;
`

const TicketList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function PricePlant() {
  return (
    <ContentArea className='booking-area ticket-price'>
      <Title style={{ color: 'white' }}>NFT ĐẶC BIỆT</Title>
      <TicketList>
        {TICKET_DETAILS.map((ticket) => {
          return (
            <TicketPriceCard ticket={ticket} />
          )
        })}
      </TicketList>
    </ContentArea>
  )
}
