import Web3, {providers} from "web3";
import TICKET_CONTRACT_ABI from '../abis/ticket.json'
import BUSD_CONTRACT_ABI from '../abis/busd.json'
import USDT_CONTRACT_ABI from '../abis/usdt.json'
import {
  BUSD_CONTRACT_ADDRESS,
  USDT_CONTRACT_ADDRESS,
  TICKET_CONTRACT_ADDRESS,
  BSC_RPC,
  MAX_UINT
} from '../config/constant'

export const web3 = new Web3( new providers.HttpProvider(BSC_RPC))
export const web3_current_provider = new Web3(window.ethereum)

export const BUSD_CONTRACT = new web3_current_provider.eth.Contract(BUSD_CONTRACT_ABI, BUSD_CONTRACT_ADDRESS)
export const USDT_CONTRACT = new web3_current_provider.eth.Contract(USDT_CONTRACT_ABI, USDT_CONTRACT_ADDRESS)
export const ticket_contract = new web3_current_provider.eth.Contract(TICKET_CONTRACT_ABI, TICKET_CONTRACT_ADDRESS)

export const connect_wallet = async () => {
  if (!window.ethereum) {
    throw new Error('Web3 provider not found. Please install Metamask and try again.')
  }
  const chainId = await window.ethereum.request({ method: 'eth_chainId' })
  if (chainId !== '0x38') {
    throw new Error('Wrong network. Please switch to Binance Smart Chain.')
  }
  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  const current_account = accounts[0]
  return current_account
}

export const is_wrong_network = async () => {
  const chainId = await window.ethereum.request({ method: 'eth_chainId' })
  return chainId !== '0x38'
}

export const change_network = async () => {
  const change_network = await window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{chainId: '0x38'}] //change to binance smart chain
  })
  return change_network
}

export const get_allowance = async (account) => {
  const allowance = await BUSD_CONTRACT.methods.allowance(account, TICKET_CONTRACT_ADDRESS).call()
  return allowance
}

export const approve_token = async (account, token_name) => {
  if (token_name === 'BUSD') {
    const approve = await BUSD_CONTRACT.methods.approve(TICKET_CONTRACT_ADDRESS, MAX_UINT).send({from: account})
    return approve
  }
  const approve = await USDT_CONTRACT.methods.approve(TICKET_CONTRACT_ADDRESS, MAX_UINT).send({from: account})
  return approve
}

export const checksum_address = (address) => {
  return web3.utils.toChecksumAddress(address)
}