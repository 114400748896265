import bookingMap from '../assets/img/booking-map.png';

// export const SHOWS = [
//   {
//     id: 1,
//     name: "Đêm Nhạc Trịnh Công Sơn - Như Một Loài Rêu Phong",
//     price: "15 BUSD",
//     date: "02/07/2022",
//     image: "https://images.tkbcdn.com/1/780/300/Upload/eventcover/2022/06/26/6FE631.jpg",
//     map: bookingMap,
//   },
//   {
//     id: 2,
//     name: "Đêm nhạc Trịnh Thăng Bình - Tăng Phúc",
//     price: "25 BUSD",
//     date: "01/07/2022",
//     image: "https://images.tkbcdn.com/1/780/300/Upload/eventcover/2022/06/08/2ECE8E.jpg",
//     map: bookingMap,
//   },
//   {
//     id: 3,
//     name: "Show nhạc rock 'Lửa gào'",
//     price: "10 BUSD",
//     date: "04/07/2022",
//     image: "https://images.tkbcdn.com/1/780/300/Upload/eventcover/2022/06/27/3485EE.jpg",
//     map: bookingMap,
//   },
//   {
//     id: 4,
//     name: "The “Traditional Water Puppet Show”",
//     price: "15 BUSD",
//     date: "01/07/2022",
//     image: "https://images.tkbcdn.com/1/780/300/Upload/eventcover/2022/06/16/3D9010.jpg?w=360&maxheight=137&mode=crop&anchor=topcenter",
//     map: bookingMap,
//   },
//   {
//     id: 5,
//     name: "Amazing Show: TRUNG QUÂN IDOL - ÁI PHƯƠNG",
//     price: "20 BUSD",
//     date: "01/07/2022",
//     image: "https://images.tkbcdn.com/1/780/300/Upload/eventcover/2022/06/27/3C0214.jpg",
//     map: bookingMap,
//   },
//   {
//     id: 6,
//     name: "Liveshow: Mong ước kỷ niệm xưa",
//     price: "25 BUSD",
//     date: "01/07/2022",
//     image: "https://images.tkbcdn.com/1/780/300/Upload/eventcover/2022/05/30/261EBC.jpg",
//     map: bookingMap,
//   }
// ]

export const TICKETS = [
  {
    _id: "62ce421397653c892cc13012",
    nftId: "0",
    owner: "0x81f403fe697cfcf2c21c019bd546c6b36370458c",
    eventId: 1,
    customer: {
        fullName: "Chinh",
        phone: "0989383129",
        email: "chinh@gmail.com"
    },
    qrCode: "https://chart.googleapis.com/chart?cht=qr&chs=100x100&chl=https://ticket-api.thebros.studio/nft/0",
    checkedIn: false,
    __v: 0
  },
  {
    _id: "62ce421397653c892cc13012",
    nftId: "1",
    owner: "0x81f403fe697cfcf2c21c019bd546c6b36370458c",
    eventId: 1,
    customer: {
        fullName: "Chinh",
        phone: "0989383129",
        email: "chinh@gmail.com"
    },
    qrCode: "https://chart.googleapis.com/chart?cht=qr&chs=100x100&chl=https://ticket-api.thebros.studio/nft/0",
    checkedIn: false,
    __v: 0
  },
]

export const SEATS = [
  {
    ticket_type: 'THE-SUN',
    seat_map: [
      [
        {
          seat_id: 'A-1',
          status: 'available',
        },
        {
          seat_id: 'A-2',
          status: 'available',
        },
        {
          seat_id: 'A-3',
          status: 'available',
        },
        {
          seat_id: 'A-4',
          status: 'selected',
        },
        {
          seat_id: 'A-5',
          status: 'selected',
        },
        {
          seat_id: 'A-6',
          status: 'available',
        },
        {
          seat_id: 'A-7',
          status: 'available',
        },
        {
          seat_id: 'A-8',
          status: 'available',
        },
        {
          seat_id: 'A-9',
          status: 'available',
        },
        {
          seat_id: 'A-10',
          status: 'available',
        },
      ],
      [
        {
          seat_id: 'B-1',
          status: 'available',
        },
        {
          seat_id: 'B-2',
          status: 'available',
        },
        {
          seat_id: 'B-3',
          status: 'available',
        },
        {
          seat_id: 'B-4',
          status: 'available',
        },
        {
          seat_id: 'B-5',
          status: 'available',
        },
        {
          seat_id: 'B-6',
          status: 'available',
        },
        {
          seat_id: 'B-7',
          status: 'selected',
        },
        {
          seat_id: 'B-8',
          status: 'selected',
        },
        {
          seat_id: 'B-9',
          status: 'available',
        },
        {
          seat_id: 'B-10',
          status: 'available',
        },
      ],
    ]
  },
  {
    ticket_type: 'THE-GIFT',
    seat_map: [
      [
        {
          seat_id: 'A-1',
          status: 'available',
        },
        {
          seat_id: 'A-2',
          status: 'selected',
        },
        {
          seat_id: 'A-3',
          status: 'selected',
        },
        {
          seat_id: 'A-4',
          status: 'selected',
        },
        {
          seat_id: 'A-5',
          status: 'selected',
        },
        {
          seat_id: 'A-6',
          status: 'available',
        },
        {
          seat_id: 'A-7',
          status: 'available',
        },
        {
          seat_id: 'A-8',
          status: 'available',
        },
        {
          seat_id: 'A-9',
          status: 'available',
        },
        {
          seat_id: 'A-10',
          status: 'available',
        },
      ],
      [
        {
          seat_id: 'B-1',
          status: 'available',
        },
        {
          seat_id: 'B-2',
          status: 'available',
        },
        {
          seat_id: 'B-3',
          status: 'selected',
        },
        {
          seat_id: 'B-4',
          status: 'available',
        },
        {
          seat_id: 'B-5',
          status: 'available',
        },
        {
          seat_id: 'B-6',
          status: 'available',
        },
        {
          seat_id: 'B-7',
          status: 'selected',
        },
        {
          seat_id: 'B-8',
          status: 'selected',
        },
        {
          seat_id: 'B-9',
          status: 'available',
        },
        {
          seat_id: 'B-10',
          status: 'available',
        },
      ],
    ]
  },
  {
    ticket_type: 'THE-UNIVERSE',
    seat_map: [
      [
        {
          seat_id: 'A-1',
          status: 'selected',
        },
        {
          seat_id: 'A-2',
          status: 'selected',
        },
        {
          seat_id: 'A-3',
          status: 'available',
        },
        {
          seat_id: 'A-4',
          status: 'available',
        },
        {
          seat_id: 'A-5',
          status: 'available',
        },
        {
          seat_id: 'A-6',
          status: 'available',
        },
        {
          seat_id: 'A-7',
          status: 'available',
        },
        {
          seat_id: 'A-8',
          status: 'selected',
        },
        {
          seat_id: 'A-9',
          status: 'selected',
        },
        {
          seat_id: 'A-10',
          status: 'selected',
        },
      ],
      [
        {
          seat_id: 'B-1',
          status: 'available',
        },
        {
          seat_id: 'B-2',
          status: 'available',
        },
        {
          seat_id: 'B-3',
          status: 'selected',
        },
        {
          seat_id: 'B-4',
          status: 'selected',
        },
        {
          seat_id: 'B-5',
          status: 'available',
        },
        {
          seat_id: 'B-6',
          status: 'available',
        },
        {
          seat_id: 'B-7',
          status: 'selected',
        },
        {
          seat_id: 'B-8',
          status: 'selected',
        },
        {
          seat_id: 'B-9',
          status: 'available',
        },
        {
          seat_id: 'B-10',
          status: 'available',
        },
      ],
    ]
  },
  {
    ticket_type: 'THE-MOON',
    seat_map: [
      [
        {
          seat_id: 'A-1',
          status: 'selected',
        },
        {
          seat_id: 'A-2',
          status: 'available',
        },
        {
          seat_id: 'A-3',
          status: 'available',
        },
        {
          seat_id: 'A-4',
          status: 'selected',
        },
        {
          seat_id: 'A-5',
          status: 'selected',
        },
        {
          seat_id: 'A-6',
          status: 'available',
        },
        {
          seat_id: 'A-7',
          status: 'available',
        },
        {
          seat_id: 'A-8',
          status: 'selected',
        },
        {
          seat_id: 'A-9',
          status: 'available',
        },
        {
          seat_id: 'A-10',
          status: 'available',
        },
      ],
      [
        {
          seat_id: 'B-1',
          status: 'available',
        },
        {
          seat_id: 'B-2',
          status: 'available',
        },
        {
          seat_id: 'B-3',
          status: 'selected',
        },
        {
          seat_id: 'B-4',
          status: 'available',
        },
        {
          seat_id: 'B-5',
          status: 'available',
        },
        {
          seat_id: 'B-6',
          status: 'available',
        },
        {
          seat_id: 'B-7',
          status: 'selected',
        },
        {
          seat_id: 'B-8',
          status: 'selected',
        },
        {
          seat_id: 'B-9',
          status: 'available',
        },
        {
          seat_id: 'B-10',
          status: 'available',
        },
      ],
    ]
  },
  {
    ticket_type: 'THE-STAR',
    seat_map: [
      [
        {
          seat_id: 'A-1',
          status: 'available',
        },
        {
          seat_id: 'A-2',
          status: 'available',
        },
        {
          seat_id: 'A-3',
          status: 'available',
        },
        {
          seat_id: 'A-4',
          status: 'selected',
        },
        {
          seat_id: 'A-5',
          status: 'selected',
        },
        {
          seat_id: 'A-6',
          status: 'available',
        },
        {
          seat_id: 'A-7',
          status: 'available',
        },
        {
          seat_id: 'A-8',
          status: 'selected',
        },
        {
          seat_id: 'A-9',
          status: 'selected',
        },
        {
          seat_id: 'A-10',
          status: 'selected',
        },
      ],
      [
        {
          seat_id: 'B-1',
          status: 'available',
        },
        {
          seat_id: 'B-2',
          status: 'available',
        },
        {
          seat_id: 'B-3',
          status: 'available',
        },
        {
          seat_id: 'B-4',
          status: 'selected',
        },
        {
          seat_id: 'B-5',
          status: 'selected',
        },
        {
          seat_id: 'B-6',
          status: 'available',
        },
        {
          seat_id: 'B-7',
          status: 'selected',
        },
        {
          seat_id: 'B-8',
          status: 'selected',
        },
        {
          seat_id: 'B-9',
          status: 'available',
        },
        {
          seat_id: 'B-10',
          status: 'available',
        },
      ],
    ]
  },
]

export const SHOWS = [
  {
    id: 'hay-fest',
    name: "HAY FEST - GLAMPING MUSIC FESTIVAL",
    price: "",
    date: "06/08/2022",
    image: "https://thebros.vn/hfest/banner.jpg",
    logo: "https://dev.api.thebros.vn/files/imgs/file-1656263779418.png",
    avatar: 'https://api.thebros.vn/files/imgs/file-1657280702930.jpg',
    map: 'https://api.thebros.vn/files/imgs/file-1656690925389.jpeg',
  },
]

export const TICKET_DETAILS = [
  {
    ticket_id: 1,
    ticket_name: 'EARLY BIRD NFT',
    ticket_type: 'HẠNG GENERAL ADMISSION',
    ticket_price: 20,
    ticket_description: [
      '- Vé dành cho 1 khách',
      '- Vị trí đứng: Khu GA',
      '- Quà tặng : Lightstick, Quạt nhựa',
      '- Check-in: Không giới hạn'
    ],
    status: 'sold-out'
  },
  // {
  //   ticket_id: 2,
  //   ticket_name: 'HAY PHẾT',
  //   ticket_type: 'HẠNG GENERAL ADMISSION',
  //   ticket_price: 30,
  //   ticket_description: [
  //     '- Vé dành cho 1 khách',
  //     '- Vị trí đứng: Khu GA',
  //     '- Quà tặng : Lightstick, Quạt nhựa',
  //     '- Check-in: Không giới hạn'
  //   ],
  //   status: 'available'
  // },
  // {
  //   ticket_id: 3,
  //   ticket_name: 'NHÓM 5 HAY PHẾT',
  //   ticket_type: 'HẠNG GENERAL ADMISSION',
  //   ticket_price: 140,
  //   ticket_description: [
  //     '- Vé dành cho 5 khách',
  //     '- Vị trí đứng: Khu GA',
  //     '- Quà tặng : Lightstick, Quạt nhựa',
  //     '- Check-in: Không giới hạn (một NFT-TICKET check-in cho 5 khách)'
  //   ],
  //   status: 'available'
  // },
  // {
  //   ticket_id: 4,
  //   ticket_name: 'NHÓM 10 HAY PHẾT',
  //   ticket_type: 'HẠNG GENERAL ADMISSION',
  //   ticket_price: 270,
  //   ticket_description: [
  //     '- Vé dành cho 10 khách',
  //     '- Vị trí đứng: Khu GA',
  //     '- Quà tặng : Lightstick, Quạt nhựa',
  //     '- Check-in: Không giới hạn (một NFT-TICKET check-in cho 10 khách)'
  //   ],
  //   status: 'available'
  // },
  // {
  //   ticket_id: 5,
  //   ticket_name: 'HAY VIP LẮM',
  //   ticket_type: 'HẠNG VIP',
  //   ticket_price: 75,
  //   ticket_description: [
  //     '- Vé dành cho 1 khách',
  //     '- Vị trí đứng: Khu VIP',
  //     '- Quà tặng: Lightstick, Tấm trải Camping,Túi Tote, Quạt nhựa',
  //     '- Check-in: Không giới hạn'
  //   ],
  //   status: 'available'
  // },
  // {
  //   ticket_id: 6,
  //   ticket_name: 'NHÓM 5 HAY VIP LẮM',
  //   ticket_type: 'HẠNG VIP',
  //   ticket_price: 430,
  //   ticket_description: [
  //     '- Vé dành cho 5 khách',
  //     '- Gồm: 1 lều vintage và đồ trang trí camping, 1 phần F&B cho 5 người',
  //     '- Vị trí đứng: Khu VIP',
  //     '- Quà tặng: Lightstick, Tấm trải Camping,Túi Tote, Quạt nhựa',
  //     '- Check-in: Không giới hạn (một NFT-TICKET check in cho 5 khách)'
  //   ],
  //   status: 'available'
  // },
  // {
  //   ticket_id: 7,
  //   ticket_name: 'NHÓM 5 HAY VIP LẮM',
  //   ticket_type: 'HẠNG VIP',
  //   ticket_price: 850,
  //   ticket_description: [
  //     '- Vé dành cho 10 khách',
  //     '- Gồm: 1 lều vintage và đồ trang trí camping, 1 phần F&B cho 10 người',
  //     '- Vị trí đứng: Khu VIP',
  //     '- Quà tặng: Lightstick, Tấm trải Camping,Túi Tote, Quạt nhựa',
  //     '- Check-in: Không giới hạn (một NFT-TICKET check in cho 10 khách)'
  //   ],
  //   status: 'available'
  // },
]