import { Col } from 'antd'
import { useEffect, useState } from 'react'
import { NavLink, useParams } from "react-router-dom"
import styled from 'styled-components'
import FestivalArea from '../../components/HAYFEST/FestivalArea'
import FestivalBookingTicket from '../../components/HAYFEST/FestivalBookingTicket'
import FestivalDestination from '../../components/HAYFEST/FestivalDestination'
import FestivalDetail from '../../components/HAYFEST/FestivalDetail'
import FestivalPricePlan from '../../components/HAYFEST/FestivalPricePlan'
import PaymentModal from '../../components/HAYFEST/PaymentModal'
import { SHOWS } from '../../config/mockup'


const DetailPage = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: #040d21;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  &.mobile{
    padding: 25px;
  }
`

const Product = styled(Col)`
  background: #FFE818;
`

const ShowImage = styled.img`
  width: 100%;
`

export default function ProductDetail() {
  const [product, set_product] = useState(undefined)
  const [guest, set_guest_info] = useState(undefined)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { id } = useParams()

  useEffect(() => {
    const this_product = SHOWS.find((show) => show.id == id)
    set_product(this_product)
  }, [id])

  const submit_guest_info = (guest_info) => {
    setIsModalVisible(true)
    set_guest_info(guest_info)
  };

  const render_space_effect = () => {
    return (
      <div className="overflow-hidden" style={{ height: '100vh', position: 'fixed', width: '100%', top: 1 }}>
        <img src="https://thebros.vn/hero-glow.svg" alt="" className="js-warp-hide position-absolute overflow-hidden home-hero-glow events-none"/>
        <div className="signup-space">
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
          <div className="signup-stars"></div>
        </div>
      </div>
    )
  }

  if (!product) return (
    <DetailPage style={{ alignItems: 'center' }}>
      {render_space_effect()}
      <Col span={24} className='container'>
        <Col span={24}>
          <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold', marginBottom: 30 }}>Không tìm thấy show</div>
          <div style={{ color: '#fff', fontSize: 20, fontWeight: 'bold' }}>
            Quay trở lại <NavLink to='/'>Trang chủ</NavLink> và thử lại
          </div>
        </Col>
      </Col>
    </DetailPage>
  )

  return (
    <DetailPage>
      <Col span={24} className='container'>
        <Product span={24}>
          <ShowImage src={product.image} alt='' />
          <FestivalDetail />
          <FestivalDestination />
          <FestivalArea product={product}/>
          <FestivalPricePlan />
          <FestivalBookingTicket submit_guest_info={submit_guest_info} />
        </Product>
      </Col>
      {guest && (
        <PaymentModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} guest={guest} />
      )}
    </DetailPage>
  )
}
