import React from 'react'
import styled from 'styled-components'
import { useWindowSize } from '../../../utils/utils'

const FestivalDetail = styled.div`
`

const ShowText = styled.div`
  font-size: 18px;
  font-weight: bold;
  &.mobile {
    font-size: 16px;
  }
  &.name {
    font-size: 23px;
    &.mobile {
      font-size: 18px;
    }
  }
`

const FestLogo = styled.img`
  &.mobile {
    width: 200px;
  }
`

const ContentArea = styled.div`
  margin: 50px auto;
  max-width: 570px;
  display: flex;
  flex-direction: column;
  padding: 6px;
  font-size: 16px;
`

export default function Festival() {
  const window_size = useWindowSize()
  const is_mobile = window_size.width < 992

  return (
    <FestivalDetail>
      <div className='product-item'>
        <div className='right-side'>
          <div className='info'>
            <div>
              <ShowText style={{ margin: '50px 0 30px' }}>
                <FestLogo className={is_mobile ? 'mobile' : ''} src='https://thebros.vn/hfest/logo.png' />
              </ShowText>
              <ShowText>GLAMPING MUSIC FESTIVAL</ShowText>
            </div>
          </div>
        </div>
      </div>
      <ContentArea className='description'>
        <span className="info-text">
          <b>Sự kiện âm nhạc quốc tế lớn nhất mùa hè </b>này sẽ diễn ra tại Việt Nam vào 6 tháng 8 năm 2022.
        </span>
        <span className="info-text">
          Lần đầu tiên tại Việt Nam, <b>các boyband US-UK danh tiếng nhất trong "thời kỳ hoàng kim của các boyband" thập niên 80s - 90s</b> cùng trình diễn với <b>các nghệ sĩ tài năng của âm nhạc Việt Nam đương đại.</b>
        </span>
        <span className="info-text">
          <b>Glamping Music Festival ĐẦU TIÊN</b> tại Việt Nam với âm nhạc đa dạng và các chuỗi hoạt động nghệ thuật đường phố, ẩm thực kết hợp glamping dành cho giới trẻ. Hứa hẹn một đại tiệc âm nhạc đa sắc màu với âm thanh, ánh sáng, sân khấu và dàn dựng đạt chuẩn quốc tế.
        </span>
        <div className="ant-row ant-row-center" style={{ margin: '64px -16px', maxWidth: '960px', rowGap: '32px' }}>
          <div className="ant-col ant-col-7 flex-hcenter" style={{ padding: '0 16px', maxWidth: '320px' }}>
            <div style={{ fontSize: 60, fontWeight: 'bold' }}>18</div>
            <span style={{ textAlign: 'center', fontSize: 16 }}>Nghệ sĩ, nhóm nhạc Việt Nam và quốc tế</span>
          </div>
          <div style={{ width: '1px', backgroundColor: 'black' }}></div>
          <div className="ant-col ant-col-7 flex-hcenter" style={{ padding: '0 16px', maxWidth: '320px' }}>
            <div style={{ fontSize: 60, fontWeight: 'bold' }}>14</div>
            <span style={{ textAlign: 'center', fontSize: 16 }}>Giờ trình diễn</span>
          </div>
          <div style={{ width: '1px', backgroundColor: 'black' }}></div>
          <div className="ant-col ant-col-7 flex-hcenter" style={{ padding: '0 16px', maxWidth: '320px' }}>
            <div style={{ fontSize: 60, fontWeight: 'bold' }}>05</div>
            <span style={{ textAlign: 'center', fontSize: 16 }}>Khu vực hoạt động cả ngày</span>
          </div>
        </div>
      </ContentArea>
    </FestivalDetail>
  )
}
